<template>
  <div class="next-steps-page" ref="pageRef">
    <div class="margin-bottom-ml ri-medium-bold-headline">הצעדים הבאים שלי</div>
    <div class="next-steps-sections">
      <template v-for="actionType in orderedActionTypes">
        <div :key="actionType" v-if="stepsByActionType[actionType].length > 0">
          <div class="margin-bottom-sm">{{ actionType }}</div>
          <div class="next-steps-section-cards">
            <component v-for="step in stepsByActionType[actionType]" :key="step.actionKey" :is="step.cardComponent"/>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import { ActionKeys, ActionTypesOrder, actionKeyToComponent, progressStatusToActionKeys } from './my-progress-next-steps.consts';

export default {
  name: 'NextStepsPage',
  async created() {
    if (_.isEmpty(this.cashflow)) {
      await this.initBudgets({ budgetDate: 'current' });
    }
    await Promise.all([
      this.fetchActiveChallenge(),
      this.initCustomerProgress(),
      this.initAdvisory(),
      this.initEligibleForPlanAhead(),
    ]);
    this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BEIGE,
      textColor: BaseUI.Colors.riseupDarkGreen,
      logoColor: BaseUI.Colors.riseupDarkGreen,
    });
    this.$nextTick(() => {
      this.$refs.pageRef?.scrollIntoView({ block: 'start' });
    });
  },
  computed: {
    ...mapState('customerProgress', ['customerProgress']),
    ...mapState('cashflowView', ['cashflow']),
    ...mapState('planAhead', ['eligibleForPlanAhead']),
    ...mapState('challenge', ['activeChallenge']),
    ...mapGetters('advisory', ['eligibleForAdvisory']),
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth', 'trackingCategories']),
    progressStatus() {
      return this.customerProgress?.progressState?.progressStatus;
    },
    steps() {
      if (!this.progressStatus) {
        return [];
      }
      return progressStatusToActionKeys[this.progressStatus].map(actionKey => {
        return { actionKey, ...actionKeyToComponent[actionKey] };
      });
    },
    stepsByActionType() {
      return _.chain(this.steps)
        .filter(step => this.isStepVisible(step.actionKey))
        .groupBy('actionType')
        .value();
    },
    orderedActionTypes() {
      return _.orderBy(Object.keys(this.stepsByActionType), actionType => ActionTypesOrder.indexOf(actionType), 'asc');
    },
    categoriesForChallenge() {
      return this.trackingCategories?.filter(item => item.frequency === 'monthly')?.length;
    },
  },
  methods: {
    ...mapActions('customerProgress', ['initCustomerProgress']),
    ...mapActions('appHeader', ['setAppHeaderProps']),
    ...mapActions('cashflowView', ['initBudgets']),
    ...mapActions('challenge', ['fetchActiveChallenge']),
    ...mapActions('advisory', ['initAdvisory']),
    ...mapActions('planAhead', ['initEligibleForPlanAhead']),
    isStepVisible(actionKey) {
      switch (actionKey) {
      case ActionKeys.SET_MONTHLY_GOAL:
        return this.predictedBalanceAtEndOfMonth >= 0;
      case ActionKeys.SET_CATEGORY_GOAL:
        return this.categoriesForChallenge > 0 || !_.isEmpty(this.activeChallenge);
      case ActionKeys.PLAN_AHEAD:
        return this.eligibleForPlanAhead;
      case ActionKeys.ADVISORY:
        return this.eligibleForAdvisory;
      default:
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/global-variables.scss';

.next-steps-page {
  display: flex;
  flex-direction: column;
  padding: $ri-spacing-ml $ri-spacing-ml $ri-spacing-xxl $ri-spacing-ml;
  background: $riseup_beige;
  scroll-margin-top: $app_header_height;

  .next-steps-sections {
    display: flex;
    flex-direction: column;
    row-gap: $ri-spacing-xl;

    .next-steps-section-cards {
      display: flex;
      flex-direction: column;
      row-gap: $ri-spacing-sm;
    }
  }
}
</style>
